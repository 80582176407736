import React, { useState } from "react"
import {
  WithdrawalQuery,
  WireTransferWithdrawalQuery,
  SkrillWithdrawalQuery,
  NetellerWithdrawalQuery,
  VisaMasterCardWithdrawalQuery,
} from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  BreadCrumbBanner,
  SubscriptionPanel,
  AddFormFields,
} from "../../components/shared"
import { SUCCESS_MESSAGES } from "../../constants"
import { sendEmail } from "../../actions"
import { Tab, Nav, Form, Button } from "react-bootstrap"
const Withdrawal = () => {
  const language = useSelector(state => state.language)
  const {
    banner_image,
    page_heading,
    icon_neteller,
    icon_skrill,
    icon_wire_transfer,
    icon_visa_mastercard,
    page_description,
  } = WithdrawalQuery(language)
  const wireTransferWithdrawalFormData = WireTransferWithdrawalQuery(language)
  const SkrillWithdrawalFormData = SkrillWithdrawalQuery(language)
  const NetellerWithdrawalFormData = NetellerWithdrawalQuery(language)
  const VisaMasterCardWithdrawalFormData = VisaMasterCardWithdrawalQuery(
    language
  )

  const [formData, updateFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleChange = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    })
  }

  const handleSubmit = async e => {
    setIsLoading(true)
    setMessage(null)
    e.preventDefault()
    try {
      await sendEmail("contact-us", formData)
      setMessage(SUCCESS_MESSAGES.EMAIL_SENT[language])
      updateFormData({})
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <Layout>
        <BreadCrumbBanner heading={page_heading} banner_image={banner_image} />

        <section className="p-top-85 p-bottom-85  section-bg">
          <div className="container mb-5">
            <strong>{page_description[0].text}</strong>
          </div>
          <div className="timeline_area">
            <div className="tab tab--4">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 withdrawal-form">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="wire-transfer"
                    >
                      <Nav variant="pills" className="withdraw-tab">
                        <Nav.Item className="links">
                          <Nav.Link className="item" eventKey="wire-transfer">
                            <img src={icon_wire_transfer.url} alt="ddd" />
                          </Nav.Link>
                          <Nav.Link className="item" eventKey="skrill">
                            <img src={icon_skrill.url} alt="ddd" />
                          </Nav.Link>
                          <Nav.Link className="item" eventKey="neteller">
                            <img src={icon_neteller.url} alt="ddd" />
                          </Nav.Link>
                          <Nav.Link className="item" eventKey="visa_mastercard">
                            <img src={icon_visa_mastercard.url} alt="ddd" />
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content className="tab-content-body">
                        <Tab.Pane eventKey="wire-transfer">
                          <div className="row no-gutters align-items-center">
                            <div className="col-lg-12 margin-md-60">
                              <div className="text_content p-left-50 p-right-50">
                                {message ? (
                                  <div className="alert alert-success">
                                    {message}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Form onSubmit={handleSubmit}>
                                  {wireTransferWithdrawalFormData.body.map(
                                    (group, indexGroup) => (
                                      <div key={"sdfsdf" + indexGroup}>
                                        <h4>
                                          {group.primary.group_title[0].text}
                                        </h4>
                                        <AddFormFields
                                          data={group.items}
                                          label="none"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    )
                                  )}
                                  <Form.Group>
                                    <Button
                                      type="submit"
                                      disabled={isLoading}
                                      className="btn btn-primary btn-purple"
                                    >
                                      {
                                        wireTransferWithdrawalFormData
                                          .submit_button_text[0].text
                                      }
                                    </Button>
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="skrill">
                          <div>
                            <div className="row no-gutters align-items-center">
                              <div className="col-lg-12 margin-md-60">
                                <div className="text_content p-left-50 p-right-50">
                                  {message ? (
                                    <div className="alert alert-success">
                                      {message}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <Form onSubmit={handleSubmit}>
                                    {SkrillWithdrawalFormData.body.map(
                                      (group, groupInd) => (
                                        <div key={"sdfsdf" + groupInd}>
                                          <h4>
                                            {group.primary.group_title[0].text}
                                          </h4>
                                          <AddFormFields
                                            data={group.items}
                                            label="none"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      )
                                    )}
                                    <Form.Group>
                                      <Button
                                        type="submit"
                                        disabled={isLoading}
                                        className="btn btn-primary btn-purple"
                                      >
                                        {
                                          SkrillWithdrawalFormData
                                            .submit_button_text[0].text
                                        }
                                      </Button>
                                    </Form.Group>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="neteller">
                          <div>
                            <div className="row no-gutters align-items-center">
                              <div className="col-lg-12 margin-md-60">
                                <div className="text_content p-left-50 p-right-50">
                                  {message ? (
                                    <div className="alert alert-success">
                                      {message}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <Form onSubmit={handleSubmit}>
                                    {NetellerWithdrawalFormData.body.map(
                                      (group, groupInd) => (
                                        <div key={"sdfsfdsdfsdf" + groupInd}>
                                          <h4>
                                            {group.primary.group_title[0].text}
                                          </h4>
                                          <AddFormFields
                                            data={group.items}
                                            label="none"
                                            onChange={handleChange}
                                          />
                                        </div>
                                      )
                                    )}
                                    <Form.Group>
                                      <Button
                                        type="submit"
                                        disabled={isLoading}
                                        className="btn btn-primary btn-purple"
                                      >
                                        {
                                          NetellerWithdrawalFormData
                                            .submit_button_text[0].text
                                        }
                                      </Button>
                                    </Form.Group>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="visa_mastercard">
                          <div className="row no-gutters align-items-center">
                            <div className="col-lg-12 margin-md-60">
                              <div className="text_content p-left-50 p-right-50">
                                {message ? (
                                  <div className="alert alert-success">
                                    {message}
                                  </div>
                                ) : (
                                  ""
                                )}
                                <Form onSubmit={handleSubmit}>
                                  {VisaMasterCardWithdrawalFormData.body.map(
                                    (group, indexGroup) => (
                                      <div key={"sdfsdf" + indexGroup}>
                                        <h4>
                                          {group.primary.group_title[0].text}
                                        </h4>
                                        <AddFormFields
                                          data={group.items}
                                          label="none"
                                          onChange={handleChange}
                                        />
                                      </div>
                                    )
                                  )}
                                  <Form.Group>
                                    <Button
                                      type="submit"
                                      disabled={isLoading}
                                      className="btn btn-primary btn-purple"
                                    >
                                      {
                                        VisaMasterCardWithdrawalFormData
                                          .submit_button_text[0].text
                                      }
                                    </Button>
                                  </Form.Group>
                                </Form>
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(Withdrawal)
